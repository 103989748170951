import { all, fork } from 'redux-saga/effects';
import pendingDashboardActiveDataSaga from '@containers/Admin/containers/ActivityPage/saga.js';
import getPendingDashboardActivityPageDataSaga from '@containers/Admin/containers/ActivityPage/__fixtures__/__ActivityPage/saga.js';
import builderDataBuilderSaga from '@containers/Admin/containers/Builder/saga.js';
import builderDataBuilderHeaderSaga from '@containers/Admin/containers/Builder/containers/BuilderHeader/saga.js';
import pendingDashboardDataSaga from '@containers/Admin/containers/PendingDashboard/saga.js';
import builderDataRoleManagementSaga from '@containers/Admin/containers/RoleManagement/saga.js';
import getSupervisorDashboardStimuliModuleDataSaga from '@containers/User/containers/CourseRouter/containers/MonitorHandler/containers/StimuliModule/saga.js';
import builderDataSaga from '@containers/Admin/shared/ResourcesWrapper/saga.js';
import getPendingDashboardDataAppSaga from '@containers/App/saga.js';
import watchLoginConfirmEmailActions from '@containers/App/containers/Authentication/ConfirmEmail/saga.js';
import watchLoginForgotPasswordActions from '@containers/App/containers/Authentication/ForgotPassword/saga.js';
import watchLoginResetPasswordActions from '@containers/App/containers/Authentication/ResetPassword/saga.js';
import watchLoginSignInActions from '@containers/App/containers/Authentication/SignIn/saga.js';
import watchLoginSignActions from '@containers/App/containers/Authentication/SignUp/saga.js';
import watchLoginActions from '@containers/App/containers/Authentication/VerifyEmailSent/saga.js';
import messagingSaga from '@containers/App/containers/Messaging/saga.js';
import mediaUploadSaga from '@containers/App/containers/PopUp/popups/MediaUpload/saga.js';
import videoChatSummarySaga from '@containers/App/containers/PopUp/popups/VideoChatSummary/saga.js';
import settingsSaga from '@containers/App/containers/Settings/saga.js';
import watchBillingActions from '@containers/User/containers/Billing/saga.js';
import courseSaga from '@containers/User/containers/CourseRouter/saga.js';
import lessonSaga from '@containers/User/containers/CourseRouter/containers/Lesson/saga.js';
import practiceMonitorHandlerSaga from '@containers/User/containers/CourseRouter/containers/MonitorHandler/saga.js';
import getSupervisorDashboardDataSaga from '@containers/Admin/containers/SupervisorDashboard/saga.js';
import pollSaga from '@containers/User/containers/CourseRouter/containers/Poll/saga.js';
import practiceSaga from '@containers/User/containers/CourseRouter/containers/Practice/saga.js';
import getPendingDashboardDataSaga from '@shared/MediaViewer/saga.js';
import getVideoChatSaga from '@shared/VideoChat/saga.js';
import getVideoMirrorSaga from '@shared/VideoMonitor/saga.js';
import mediaEditSaga from '@containers/App/containers/PopUp/popups/MediaEditor/saga';
import usersListSaga from '@containers/Admin/containers/UsersList/saga';
import userPageSaga from '@containers/Admin/containers/UserPage/saga';

export default function* rootSaga() {
  yield all([
    fork(pendingDashboardActiveDataSaga),
    fork(getPendingDashboardActivityPageDataSaga),
    fork(builderDataBuilderSaga),
    fork(builderDataBuilderHeaderSaga),
    fork(pendingDashboardDataSaga),
    fork(builderDataRoleManagementSaga),
    fork(getSupervisorDashboardStimuliModuleDataSaga),
    fork(builderDataSaga),
    fork(getPendingDashboardDataAppSaga),
    fork(watchLoginConfirmEmailActions),
    fork(watchLoginForgotPasswordActions),
    fork(watchLoginResetPasswordActions),
    fork(watchLoginSignInActions),
    fork(watchLoginSignActions),
    fork(watchLoginActions),
    fork(messagingSaga),
    fork(mediaUploadSaga),
    fork(mediaEditSaga),
    fork(videoChatSummarySaga),
    fork(settingsSaga),
    fork(watchBillingActions),
    fork(courseSaga),
    fork(lessonSaga),
    fork(practiceMonitorHandlerSaga),
    fork(getSupervisorDashboardDataSaga),
    fork(pollSaga),
    fork(practiceSaga),
    fork(getPendingDashboardDataSaga),
    fork(getVideoChatSaga),
    fork(getVideoMirrorSaga),
    fork(usersListSaga),
    fork(userPageSaga),
  ]);
}
