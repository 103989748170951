import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Text, { TEXT_SIZE, TEXT_COLOR } from '@components/Text';
import {
  Wrapper,
  MessageWrapper,
  StyledAvatar,
  Message,
  NewMessagesIndicator,
  SeenIndicator,
  StyledCheckmarkIcon,
} from './styles';
import {StyledPersonIcon} from "@containers/App/containers/Messaging/components/MessagesList/styles";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

class ConversationArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  parsePosts = (posts, users) => {
    const selfUser = _.find(users, { self: true });
    const otherUsers = _.compact(
      users.map((user) => {
        if (user.self) return null;
        return user.id;
      })
    );
    const parsedPosts = posts.map((post) => {
      const unread = !post.self && !post.seenBy?.includes(selfUser.id);
      const seen =
        selfUser.isMentor && _.difference(otherUsers, post.seenBy).length === 0;
      return {
        ...post,
        unread,
        seen,
      };
    });
    return parsedPosts;
  };

  linkifyText(text) {
    const linkRegex = /(\b(?:https?:\/\/|ftp:\/\/|www\.)[\w-]+(?:\.[\w-]+)+(?:\:\d+)?(?:\/\S*)?\b)|(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/gi;
    const parts = [];
    let lastIndex = 0;
    let match = linkRegex.exec(text);
    while (match !== null) {
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }
      const [fullMatch] = match;
      let linkText = fullMatch;
      let trailingPunctuation = '';
      const punctuationMatch = fullMatch.match(/[.,?!:;]+$/);
      const [punctuation = ''] = punctuationMatch || [];
      if (punctuation) {
        trailingPunctuation = punctuation;
        linkText = fullMatch.slice(0, fullMatch.length - punctuation.length);
      }
      let href = linkText;
      if (linkText.includes('@')) {
        href = `mailto:${linkText}`;
      } else if (!/^(https?:\/\/|ftp:\/\/)/i.test(linkText)) {
        href = `http://${linkText}`;
      }
      parts.push(
        <a href={href} key={lastIndex} target="_blank" rel="noopener noreferrer">
          {linkText}
        </a>,
      );
      if (trailingPunctuation) {
        parts.push(trailingPunctuation);
      }
      lastIndex = linkRegex.lastIndex;
      match = linkRegex.exec(text);
    }
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
    return parts;
  }

  render() {
    const {currentConversation, t} = this.props;
    if (_.isEmpty(currentConversation)) return null;

    const posts = this.parsePosts(
      currentConversation.Thread.Posts,
      currentConversation.Users
    );
    const lastSeenIndex = posts.findIndex((post) => post.seen);
    return (
      <Wrapper>
        {posts.map((post, index) => {
          const isFirstUnread =
            post.unread &&
            (index === posts.length - 1 || !posts[index + 1].unread);
          const isQueue =
            index !== posts.length - 1 &&
            post.self === posts[index + 1].self &&
            !isFirstUnread;
          const isLastSeen = index === lastSeenIndex;
          return (
            <div key={post.id}>
              {isFirstUnread && (
                <NewMessagesIndicator>
                  <Text
                    size={TEXT_SIZE.T3}
                    textColor={TEXT_COLOR.DISABLED}
                    margin='0 24px'
                  >
                    {t('Informative.Notifying.newMessages')}
                  </Text>
                </NewMessagesIndicator>
              )}
              <MessageWrapper self={post.self} isQueue={isQueue}>
                {!isQueue && (post.publisherUser?.avatar ? <StyledAvatar src={post.publisherUser.avatar} /> : <StyledPersonIcon />)}
                <Message self={post.self} isQueue={isQueue}>
                  <Text size={TEXT_SIZE.H4} textColor={TEXT_COLOR.DARK_BLUE}>
                    {this.linkifyText(post.payload.text)}
                  </Text>
                </Message>
              </MessageWrapper>
              {isLastSeen && (
                <SeenIndicator>
                  <StyledCheckmarkIcon />
                  <Text
                    size={TEXT_SIZE.T5}
                    textColor={TEXT_COLOR.DISABLED_LIGHT}
                  >
                    {t('Common.Statuses.seen')}
                  </Text>
                </SeenIndicator>
              )}
            </div>
          );
        })}
      </Wrapper>
    );
  }
}

ConversationArea.propTypes = {
  currentConversation: PropTypes.object,
};

export default compose(
    withTranslation()
)(ConversationArea);
