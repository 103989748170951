import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import Text from '@components/Text';
import {
  Wrapper,
  VideoWrapper,
  Title,
  StyledCTAButton,
  ImageWrapper,
  AudioPlayer,
} from './styles';
import { MEDIA_TYPES } from '@shared/Resources/types';
import {withTranslation} from "react-i18next";

export class Media extends Component {
  renderContent() {
    const { contentUrl, contentType } = this.props;
    switch (contentType) {
      case MEDIA_TYPES.VIDEO:
        return <VideoWrapper src={contentUrl} controls controlsList='nodownload' />;

      case MEDIA_TYPES.IMAGE:
        return <ImageWrapper src={contentUrl} />;

      case MEDIA_TYPES.AUDIO:
        return (
          <AudioPlayer controls>
            <source src={contentUrl} />
          </AudioPlayer>
        );
      default:
        return null;
    }
  }

  render() {
    const { onClose, description } = this.props;

    return (
      <Wrapper>
        <Title>
          <Text size='H1' textTransform='capitalize' weight='600'>
            {description}
          </Text>
        </Title>
        {this.renderContent()}
        <StyledCTAButton onClick={onClose} id='mediaCloseButton'>
          <Text size='H4' textTransform='capitalize' textColor='reversed'>
            {this.props.t('Actions.Instance.close')}
          </Text>
        </StyledCTAButton>
      </Wrapper>
    );
  }
}

Media.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.string,
  contentUrl: PropTypes.string,
  contentType: PropTypes.string,
};


export default compose(
    withTranslation()
)(Media);
