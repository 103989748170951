import { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  getFullConversationAction,
  getConversationsAction,
} from '../../../App/containers/Messaging/actions';
import {withTranslation} from "react-i18next";

export class PollingService extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.userSignedIn) {
      this.startConversationsPolling();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userSignedIn,
      currentConversation,
    } = this.props;
    if (userSignedIn) {
      this.startConversationsPolling();
      if (_.isEmpty(currentConversation) && this.fullConversationPoller) {
        this.stopFullConversationPolling();
      } else if (
        !_.isEmpty(currentConversation) &&
        !this.fullConversationPoller
      ) {
        this.startFullConversationPolling();
      } else if (
        _.get(currentConversation, 'id', undefined) !==
        _.get(prevProps, 'currentConversation.id')
      ) {
        this.stopFullConversationPolling();
        this.startFullConversationPolling();
      }
    } else {
      this.stopConversationsPolling();
      this.stopFullConversationPolling();
    }
  }

  componentWillUnmount() {
    this.stopConversationsPolling();
    this.stopFullConversationPolling();
  }

  startConversationsPolling = () => {
    if (!this.conversationsPoller) {
      this.conversationsPoller = setInterval(() => {
        this.props.dispatchGetConversations();
      }, 20000);
    }
  };

  stopConversationsPolling = () => {
    clearInterval(this.conversationsPoller);
    this.conversationsPoller = null;
  };

  startFullConversationPolling = () => {
    const { dispatchGetFullConversation, currentConversation } = this.props;
    if (!this.fullConversationPoller) {
      this.fullConversationPoller = setInterval(() => {
        dispatchGetFullConversation(currentConversation.id);
      }, 10000);
    }
  };

  stopFullConversationPolling = () => {
    clearInterval(this.fullConversationPoller);
    this.fullConversationPoller = null;
  };

  render() {
    return null;
  }
}

PollingService.propTypes = {
  userSignedIn: PropTypes.bool,
  currentConversation: PropTypes.object,
  dispatchGetConversations: PropTypes.func,
  dispatchGetFullConversation: PropTypes.func,
};

export const mapStateToProps = (state) => {
  const userInfo = state.getIn(['app', 'authentication', 'user']).toJS();
  const messagingState = state.getIn(['messaging'])
    ? state.getIn(['messaging']).toJS()
    : {};
  const { currentConversation } = messagingState;
  const userSignedIn = !_.isEmpty(userInfo);
  return {
    userSignedIn,
    currentConversation,
  };
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatchGetConversations: () =>
      dispatch(getConversationsAction()),
    dispatchGetFullConversation: (id) =>
      dispatch(getFullConversationAction(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, withTranslation())(PollingService);
