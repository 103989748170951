import { useTranslation } from 'react-i18next';
import Text from '@components/Text';
import { ACTIVITY_MEDIA_TYPES } from '@shared/Resources/types';
import { ActivityTitle, SkillTitle } from './styles';

/**
 * Lesson activity panel component
 */
export const LessonActivityPanel = ({ activity }) => {
  const { t } = useTranslation();
  const { roles } = useSelector((state) => state.getIn(['app', 'authentication', 'user']).toJS());
  const isClinicalMentor = useMemo(() => roles.includes('20') || roles.includes('10'), [roles]);
  const media = activity.media
    .filter(({ type }) => type === ACTIVITY_MEDIA_TYPES.VIDEO)
    .map(({ publicUrls }) => {
      if (publicUrls?.length > 0) {
        return <video controls controlsList="nodownload" src={publicUrls[0]} />;
      }
      return null;
    });

  return (
    <>
      <ActivityTitle size="H3">
        {t('UI.typeAndTitle', {
          type: activity.type.split('_').at(1),
          title: activity.lesson.name,
        })}
      </ActivityTitle>
      <SkillTitle size="H4">
        {t('UI.skillTitle', {
          title: activity.skill.name,
        })}
      </SkillTitle>
      {isClinicalMentor ? (
        <Text size="H4" margin="0 0 29px 0" textTransform="uppercase">
          {t('UI.lessonRecording')}
        </Text>
      ) : null}
      {isClinicalMentor ? media : null}
    </>
  );
};
